<template>
	<w-dialog
		v-if="value"
		:closable="false"
		max-width="500px"
		:title="value.isFolder ? $t('documents.duplicate.folder_title') : $t('documents.duplicate.document_title')"
		:value="dialog"
	>
		<w-flex>
			{{
				value.isFolder
					? $t('documents.duplicate.folder_description', { name: value.name })
					: $t('documents.duplicate.document_description', { name: value.name })
			}}
		</w-flex>
		<template v-slot:actions>
			<w-spacer />
			<w-btn flat @click="doCancel()">{{ $t('actions.cancel') }}</w-btn>
			<w-btn flat @click="doCreate()">{{ $t('actions.create') }}</w-btn>
			<w-btn flat @click="doMerge()">{{ value.isFolder ? $t('actions.merge') : $t('documents.replace_document_newversion') }}</w-btn>
		</template>
	</w-dialog>
</template>

<script>
export default {
	name: 'ShouldMergeDialog',
	props: {
		value: {
			type: [File, Object],
			required: false,
			default: null
		}
	},
	data: function () {
		return {
			dialog: false
		}
	},
	mounted: function () {
		this.dialog = true
	},
	methods: {
		doCancel: function () {
			this.$emit('close')
			this.$emit('remove')
		},
		doCreate: function () {
			this.$emit('input', false)
			this.$emit('remove')
		},
		doMerge: function () {
			this.$emit('input', true)
			this.$emit('remove')
		}
	}
}
</script>
